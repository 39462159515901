import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Este cel mai bun curs de eclesiologie care-l avem în limba română! Cursul se adresează cu predilecție pastorilor, învățătorilor și altor lideri din biserici. Dacă vrei să ai o perspectivă personală despre biserică, o perspectivă biblică ce derivă din studiul materialelor despre biserică din evanghelii și epistole, atunci trebuie neapărat să treci prin cursul de Dinamica bisericii.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Bibliografia este formată din 3 cărți a lui Gene Getz: „Să ne zidim unii pe alții”, „Măsura unui om” și „Învățătură despre biserică”; și dacă sunt lecturate și folosite separat sunt un material grozav;`}</MDXTag>
      <MDXTag name="p" components={components}>{`Imaginează-ți cum poți fi la fel ca și creștin sau slujitor după ce privești biserica prin lentila
scripturii, a istoriei și a culturii nu doar a experienței personale?! Fi sigur că după ce ai înțeles diferența dintre formă și funcție, dintre metode și principii, vei face tot ce-ți stă în putere ca să promovezi adevăratele valori în biserica locală.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Cursul oferă principii biblice pentru închinare, edificare și multiplicare, care sunt cele trei funcții de bază a bisericii, care aplicate corect pot duce la o dinamică de dorit a bisericii tale.
Fiecare slujitor are mult de învățat și de câștigat prin parcurgerea și folosirea în slujire a cursului.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    